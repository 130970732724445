export const faqs = [
  {
    title: "What exactly is conversion rate optimization?",
    desc:
      "CRO is the process of improving your website to increase the percentage of visitors who complete a desired action, like making a purchase or filling out a form. Think of it as fine-tuning your site to make it work better for you. A CRO agency (that's us) uses various techniques to achieve this goal, and we're here to guide you every step of the way!",
  },

  {
    title:
      "Do I need a lot of traffic before I can think about optimizing for conversion?",
    desc:
      "While there isn't a strict minimum, having at least 1,000 unique visitors per month is generally a good starting point. This way, you have enough data for meaningful A/B testing and analysis. We'll work with you to determine the best approach based on your current traffic levels and goals.",
  },

  {
    title:
      "What is this prediction engine thing? How does it actually help my site?",
    desc:
      "Our prediction engine is a fantastic tool that scrapes A/B test data from 78,000 active testing sites to identify proven winners. Using machine learning, it predicts what will work best on your site, leading to 3X higher win rates than traditional A/B testing methods employed by other CRO agencies. We're excited to bring this technology to your site to see amazing results!",
  },

  {
    title: "Can you really guarantee results? That sounds too good to be true!",
    desc:
      "This is what sets us apart from other CRO agencies. By adapting proven winners from our prediction engine, we guarantee a 38% lift in 90 days. And the best part? We don't charge any fees until we deliver the promised results. We're confident in what we do and committed to your success.",
  },

  {
    title: "How does your performance pricing model work?",
    desc:
      "Our CRO agency operates on a performance pricing model, meaning you don't pay any fees until we deliver the promised lift in conversions. This approach aligns our incentives with your success and ensures you only pay for results.",
  },

  {
    title:
      "CRO has a lot of design, testing, and development involved. How do you handle that?",
    desc:
      "We have a dedicated team of designers and developers who work together to implement effective changes. Spiralyze handles everything from initial analysis to the final implementation, ensuring a smooth and cohesive process. You can relax knowing every detail is taken care of by our experts.",
  },
  {
    title: "What can I expect from your full-service team?",
    desc:
      "Our full-service CRO agency takes care of everything from design and copy to development, QA, analytics, and project management. We launch your first test within two weeks and manage all aspects of implementation with our team of 170 A/B testing specialists. We're here to support you at every stage, making the process easy and stress-free.",
  },
  {
    title: "How often should I be looking at my CRO strategy?",
    desc:
      "CRO marketing is an ongoing process, and it's important to regularly review your strategy at least quarterly. This ensures it remains effective and aligned with your business goals. We’ll continuously monitor performance and make adjustments based on new data and insights. Our CRO agency is here to help you stay ahead and keep your site performing at its best.",
  },
]
