import * as React from "react"
// import Homepage from "../components/homepage-new/homepage"
import Layout from "../components/layout2023"
import Seo from "../components/seo"
// import { Home } from "../componentsv2/home/home"
import FooterV2 from "../componentsv2/footerv2/footerv2"
import { HomeV2 } from "../componentsv2/homev2/homev2"

const IndexPage = () => (
  <Layout nav={"hidden"} parentClass={`newtheme2024`}>
    <Seo
      title="CRO Agency for B2B Companies | Guaranteed Results
    "
    />
    {/* <Homepage /> */}
    {/* <Home /> */}
    <HomeV2 />
    <FooterV2 version="v2" />
  </Layout>
)

export default IndexPage
